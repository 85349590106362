<template>
  <div class="developer-add animatedBox">
    <div class="container fluid">
      <div class="card mb-2 pb-1">
        <h4 class="mainTitle py-3 mb-2 lineBottom">Add New Developer</h4>
        <div class="main-contents">
          <fd-form @submit="submitForm">
            <fd-input
              v-model="developer.name"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Name"
              name="developerName"
              type="text"
              :validators="[validators.required]"
            >
            </fd-input>
            <div class="text-right px-2 mt-4 mb-2">
              <button type="submit" class="btn main">Create</button>
            </div>
          </fd-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { DeveloperModel } from "@/models";

export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {
      developer: {},

      validators: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    async submitForm() {
      try {
        this.$store.commit("setIsLoading", true);

        await this.$store.dispatch(
          "manageDevelopers/createDeveloper",
          DeveloperModel.postPayload(this.developer)
        );

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "New developer is created successfully."
        });

        this.$router.push({ name: "ManageDevelopers" });
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        // this.$reportError(error.response);
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: " An unexpected error occured. Please try again later."
        });
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.developer-add {
  @extend %formDesign;
}
</style>
